<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="100%" viewBox="0 0 862 196" enable-background="new 0 0 862 196" xml:space="preserve">
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M863.000000,113.666672
	C853.636902,115.971550 848.043030,112.066620 845.439392,103.044975
	C844.863953,101.050888 842.835266,99.460831 842.321838,97.466904
	C840.191406,89.192482 834.424622,88.218346 827.158447,88.899971
	C821.746216,89.407677 816.247986,89.000000 810.185913,89.000000
	C810.185913,97.360115 810.185913,105.253944 810.185913,113.569611
	C806.360657,113.569611 803.133362,113.569611 799.449951,113.569611
	C799.449951,95.314362 799.449951,76.987762 799.449951,58.000000
	C805.216980,58.000000 810.822998,57.894287 816.423706,58.022148
	C827.856445,58.283154 839.321228,58.260296 850.698608,59.227245
	C854.673889,59.565105 858.431030,62.469856 862.643921,64.101189
	C863.000000,70.354401 863.000000,76.708794 862.643677,83.430878
	C858.297424,85.876648 854.307495,87.954727 850.136108,90.127296
	C853.778992,97.092796 857.395264,104.066101 861.107544,110.987892
	C861.388977,111.512642 862.353149,111.671219 863.000000,111.999992
	C863.000000,112.444443 863.000000,112.888893 863.000000,113.666672
M832.498108,67.000000
	C825.093872,67.000000 817.689697,67.000000 810.455200,67.000000
	C810.455200,72.190010 810.455200,76.415527 810.455200,80.973213
	C822.872437,80.973213 834.839905,81.111443 846.801331,80.902885
	C851.371887,80.823204 853.102844,78.153946 853.021973,73.177528
	C852.950195,68.760109 850.733398,66.920120 846.476990,66.988853
	C842.151306,67.058716 837.823547,67.001549 832.498108,67.000000
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M643.005005,69.982971
	C644.264343,67.057449 645.509155,64.533554 646.516357,61.918190
	C647.579590,59.157265 649.130981,57.853271 652.382507,57.872387
	C655.775085,57.892326 656.973083,59.659695 658.111816,62.267437
	C663.067322,73.615952 668.103882,84.929268 673.171570,96.228241
	C673.652039,97.299461 674.554138,98.181595 675.829651,99.933029
	C681.540283,87.547356 686.864380,76.074692 692.121460,64.571404
	C695.521179,57.132103 695.795837,56.957508 705.008240,58.680977
	C702.109009,65.236099 699.340454,71.722054 696.387085,78.122726
	C691.399170,88.932541 686.169495,99.632736 681.319031,110.502869
	C679.973145,113.518875 678.100281,114.019753 675.171265,114.102997
	C672.132751,114.189362 670.765808,112.899834 669.644470,110.337975
	C664.480042,98.539131 659.182556,86.798470 653.913940,75.045395
	C653.526367,74.180862 652.989685,73.383202 652.232788,72.040886
	C646.086548,85.203148 640.012329,97.810219 634.374207,110.609428
	C633.186584,113.305412 631.891785,114.109970 629.270996,114.031364
	C626.614624,113.951698 624.599243,114.078560 623.123352,110.726639
	C616.328735,95.295357 609.025269,80.088516 601.962463,64.774391
	C601.090515,62.883759 600.566040,60.832886 599.865540,58.813019
	C608.022766,56.951210 608.800415,57.164822 611.919678,63.872452
	C617.418884,75.697876 622.719238,87.615746 628.718933,100.854370
	C633.794922,89.922760 638.340454,80.133560 643.005005,69.982971
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M172.000671,114.000000
	C165.553040,114.000000 159.605270,114.000000 153.000000,114.000000
	C153.000000,109.134407 152.999786,104.859146 153.000031,100.583893
	C153.000748,88.099396 153.115173,75.613258 152.935776,63.131351
	C152.879303,59.203529 154.259323,57.892094 158.156296,57.937023
	C172.469955,58.102055 186.787521,57.917450 201.101639,58.060524
	C204.877213,58.098263 208.719391,58.508537 212.402924,59.325302
	C221.450562,61.331463 224.998947,66.055481 224.999847,75.396721
	C225.000549,82.554497 224.627594,89.736076 225.083084,96.864746
	C225.859268,109.012375 218.540939,113.368729 207.950470,113.870209
	C196.158218,114.428589 184.319183,113.999321 172.000671,114.000000
M168.702454,67.001434
	C167.272400,67.132652 165.842331,67.263870 164.364990,67.399422
	C164.364990,80.371513 164.364990,92.911400 164.364990,105.921333
	C178.315125,105.921333 191.923889,106.335098 205.491013,105.762299
	C213.318756,105.431824 214.970673,102.783516 214.995926,94.940277
	C215.014648,89.121559 215.007904,83.302719 214.997787,77.483955
	C214.984161,69.651184 212.433777,67.043083 204.576294,67.012680
	C192.938904,66.967667 181.301224,67.000565 168.702454,67.001434
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M519.670654,112.776764
	C514.287964,110.075272 511.671814,106.337631 511.906311,100.345108
	C512.263794,91.209030 511.995148,82.048637 512.001465,72.898567
	C512.007141,64.692070 514.194458,60.841824 522.151855,59.597515
	C539.392639,56.901558 556.819153,56.771614 574.054626,59.703968
	C581.372742,60.949036 583.974548,65.311630 583.994263,72.938469
	C584.017090,81.755745 583.808350,90.578369 584.049133,99.389130
	C584.341919,110.101082 578.732727,113.364326 570.278564,113.782288
	C555.508179,114.512512 540.675537,114.027969 525.869629,113.941078
	C523.929138,113.929695 521.992493,113.261650 519.670654,112.776764
M573.000000,79.545990
	C572.999878,77.713852 573.008240,75.881660 572.997986,74.049576
	C572.965088,68.145325 571.968201,67.049416 566.065552,67.020027
	C554.073608,66.960304 542.081116,66.966293 530.089111,67.016907
	C524.068787,67.042320 523.021545,68.108292 523.006348,73.894958
	C522.984070,82.389366 523.273682,90.896255 522.888977,99.373260
	C522.688660,103.787903 524.753357,105.765236 528.445251,105.829102
	C541.431396,106.053741 554.425842,106.055519 567.412170,105.842308
	C571.021057,105.783058 573.377747,103.996964 573.098328,99.515388
	C572.705200,93.210716 572.999634,86.863174 573.000000,79.545990
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M410.000000,99.991226
	C410.000000,104.777824 410.000000,109.066612 410.000000,113.424530
	C404.445862,114.658394 400.568665,113.514061 396.320831,110.117775
	C382.028259,98.690346 367.226410,87.900253 352.634552,76.846344
	C351.086731,75.673828 349.638702,74.369606 347.165161,72.311882
	C347.165161,86.684219 347.165161,100.015457 347.165161,113.662003
	C343.174683,113.662003 339.946747,113.662003 336.000000,113.662003
	C336.000000,110.360245 336.000000,107.250214 336.000000,104.140175
	C336.000031,90.980576 335.926636,77.820152 336.074554,64.662216
	C336.098389,62.542145 336.835358,58.773010 337.713257,58.628677
	C340.404083,58.186310 343.916656,58.261955 346.046783,59.705051
	C353.270386,64.598778 360.040009,70.163185 366.976532,75.479897
	C377.570557,83.600014 388.159149,91.727234 399.611786,100.512482
	C399.779297,98.213676 399.980194,96.703094 399.984741,95.191910
	C400.013885,85.530525 399.998749,75.868996 400.000305,66.207520
	C400.001709,57.407509 400.002899,57.407509 410.000000,58.419682
	C410.000000,72.032509 410.000000,85.762962 410.000000,99.991226
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M759.999390,114.000000
	C746.883484,114.000000 734.267395,114.000000 721.000000,114.000000
	C721.000000,108.446594 720.999939,103.498650 721.000000,98.550713
	C721.000183,87.052132 721.219910,75.547394 720.898010,64.057831
	C720.759216,59.104939 722.552917,57.831371 727.242310,57.909367
	C742.069702,58.155972 756.903809,57.996883 771.735291,58.001442
	C779.410095,58.003803 779.885132,58.607731 778.269104,66.978729
	C763.158630,66.978729 747.918640,66.978729 733.014099,66.978729
	C732.517578,67.862663 732.244446,68.132637 732.219971,68.423508
	C731.163025,80.999756 731.165710,80.999992 743.698792,81.000000
	C754.355469,81.000008 765.012207,81.000000 776.056396,81.000000
	C776.056396,83.524620 776.056396,85.853798 776.056396,88.815521
	C761.659119,88.815521 747.251587,88.815521 732.427246,88.815521
	C732.427246,94.643219 732.427246,99.872887 732.427246,105.738205
	C747.608643,105.738205 762.843872,105.738205 778.530762,105.738205
	C778.530762,108.696312 778.530762,111.038155 778.530762,114.000000
	C772.406921,114.000000 766.453064,114.000000 759.999390,114.000000
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M441.057739,58.000000
	C452.042236,58.000206 462.539825,57.800430 473.023621,58.093304
	C477.591400,58.220905 482.242188,58.947758 486.655457,60.141361
	C492.328339,61.675636 494.954285,65.752106 494.995789,71.588982
	C495.015900,74.420013 494.893372,77.256035 495.019562,80.081573
	C495.391907,88.417152 490.621216,92.144554 483.470764,92.799599
	C472.094696,93.841759 460.606995,93.672119 449.165833,93.991508
	C447.033630,94.051033 444.898346,94.000023 442.158386,94.000023
	C442.158386,100.719139 442.158386,106.954834 442.158386,113.588776
	C438.654205,113.588776 435.756378,113.588776 432.426270,113.588776
	C432.426270,95.380486 432.426270,77.156235 432.426270,58.000000
	C435.080780,58.000000 437.822998,58.000000 441.057739,58.000000
M442.000000,73.599869
	C442.000000,77.207916 442.000000,80.815971 442.000000,85.000000
	C452.613403,85.000000 462.553192,85.000031 472.492981,84.999992
	C484.213440,84.999954 484.049164,84.999504 484.079529,73.221901
	C484.091614,68.542656 481.889404,67.007591 477.746185,67.006668
	C469.758301,67.004890 461.770386,67.001938 453.782501,67.000145
	C450.019348,66.999306 446.256195,67.000000 442.249573,67.000000
	C442.144318,69.384575 442.072327,71.016251 442.000000,73.599869
z"/>
    <path :fill="fontColor" opacity="1.000000" stroke="none"
          d="
M307.324036,94.644562
	C311.545349,100.933731 315.565338,106.919365 319.693298,113.065773
	C310.516937,115.428207 308.001373,114.802116 304.201508,107.043327
	C301.471771,101.469635 298.031799,99.487022 291.870453,99.852699
	C282.417328,100.413750 272.904999,100.093307 263.420898,99.946518
	C260.455719,99.900620 258.264984,100.445633 257.121246,103.484703
	C256.607971,104.848541 255.688278,106.071663 254.877884,107.309448
	C249.900818,114.911316 249.624313,115.018425 239.162872,113.374352
	C242.784943,107.955093 246.233749,102.812080 249.665192,97.657524
	C257.526001,85.849419 265.358856,74.022667 273.241882,62.229439
	C277.441498,55.946724 281.936554,56.028767 286.214355,62.495323
	C293.220581,73.086227 300.156891,83.723366 307.324036,94.644562
M289.476257,84.054054
	C286.426239,79.399582 283.376251,74.745110 279.632416,69.031853
	C274.563354,76.919029 269.967407,84.070061 265.191376,91.501282
	C275.316376,91.501282 284.490479,91.501282 294.181641,91.501282
	C292.496582,88.824280 291.187866,86.745110 289.476257,84.054054
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M863.000000,165.600006
	C854.225281,166.000000 845.450500,166.000000 836.339355,166.000000
	C836.339355,157.341309 836.339355,148.981293 836.339355,140.000000
	C844.923462,140.000000 853.493042,140.000000 862.531372,140.000000
	C863.000000,141.066666 863.000000,142.133347 862.531372,143.600006
	C855.143677,144.000000 848.224548,144.000000 841.359863,144.000000
	C841.359863,146.305054 841.359863,148.015991 841.359863,149.492111
	C848.818359,150.011826 855.909180,150.505920 863.000000,151.000000
	C863.000000,151.750000 863.000000,152.500000 862.559875,153.442596
	C855.211365,153.635193 848.303040,153.635193 841.329102,153.635193
	C841.329102,156.932709 841.329102,159.307098 841.329102,162.000000
	C848.769409,162.000000 855.884705,162.000000 863.000000,162.000000
	C863.000000,163.066666 863.000000,164.133347 863.000000,165.600006
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M669.069580,164.587524
	C669.029480,156.630081 669.035400,149.135178 669.056763,141.640320
	C669.057678,141.326111 669.201172,141.012283 669.427124,140.091614
	C677.522522,140.091614 685.797180,139.786560 694.015015,140.334183
	C695.780518,140.451828 698.214966,143.399307 698.817078,145.483551
	C700.158386,150.126083 698.650391,153.870941 692.996094,154.428482
	C695.042786,158.367279 696.912781,161.966064 698.806641,165.610779
	C694.844666,166.866043 692.620178,165.535736 691.454773,161.954163
	C689.408081,155.664658 685.424072,152.862671 678.465942,153.837494
	C675.027954,154.319138 673.690796,155.242249 673.965454,158.643265
	C674.149597,160.923004 674.000549,163.229645 674.000549,166.274628
	C671.986145,165.769363 670.552307,165.409714 669.069580,164.587524
M690.120117,144.001953
	C684.841370,144.001953 679.562683,144.001953 673.972961,144.001953
	C674.081482,146.341644 674.161194,148.060028 674.240417,149.767639
	C681.035522,149.767639 687.315491,149.767639 693.595398,149.767639
	C693.979065,149.379700 694.362732,148.991760 694.746399,148.603836
	C693.510925,147.073044 692.275452,145.542267 690.120117,144.001953
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M654.638916,140.011230
	C655.862183,141.024048 656.620728,142.028900 658.109192,144.000671
	C649.895447,144.000671 643.273193,144.128555 636.659363,143.947327
	C633.294922,143.855164 631.981140,144.877853 631.922791,148.459152
	C631.698425,162.232162 631.583130,162.112289 647.032898,162.075531
	C651.361267,162.065216 652.804138,160.577881 651.577209,156.133789
	C648.672058,156.133789 645.726562,156.133789 642.781128,156.133789
	C642.425537,155.735931 642.069946,155.338074 641.714355,154.940231
	C642.655151,153.962845 643.532410,152.227432 644.548035,152.142197
	C648.480408,151.812271 652.456909,152.008072 656.692444,152.008072
	C656.818848,153.127029 656.969238,153.904678 656.985413,154.685104
	C657.202759,165.146042 656.366089,166.000046 645.963623,165.999756
	C641.806763,165.999649 637.634155,166.221664 633.497620,165.931091
	C629.497681,165.650146 626.669067,163.923599 626.952209,159.117706
	C627.186523,155.140930 626.981018,151.139252 627.005005,147.148834
	C627.034851,142.182312 629.073486,140.091370 634.221741,140.022766
	C640.871460,139.934158 647.523315,140.002533 654.638916,140.011230
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M731.801575,161.999969
	C737.054016,162.808624 738.387817,160.598648 738.080444,156.085449
	C737.731812,150.967331 738.000000,145.807220 738.000000,140.337769
	C740.015808,140.337769 741.730347,140.337769 743.982605,140.337769
	C743.982605,146.653580 744.271301,152.956116 743.879456,159.216064
	C743.625183,163.277054 740.795898,165.794800 736.661987,165.938934
	C730.506287,166.153580 724.306580,166.374619 718.183655,165.877655
	C712.838928,165.443848 710.903320,163.893524 710.982666,158.093491
	C711.062195,152.285599 711.000183,146.475754 711.000183,140.298279
	C712.669983,140.203735 714.050537,140.125549 715.450012,140.046295
	C715.678406,140.787354 715.940369,141.247025 715.943909,141.708679
	C715.985840,147.205017 716.065491,152.702988 715.970886,158.197693
	C715.922913,160.985672 717.066101,162.109192 719.832825,162.026459
	C723.660583,161.912003 727.494385,161.999161 731.801575,161.999969
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M758.029968,165.977264
	C757.052124,165.285248 756.093689,164.610733 756.084290,163.923218
	C755.977844,156.149796 756.016785,148.374359 756.016785,140.034439
	C764.117126,140.034439 771.879944,139.735733 779.604980,140.146454
	C784.240723,140.392899 785.560852,143.887009 785.021912,151.463989
	C784.724792,155.640945 781.845093,155.933792 778.734253,155.988495
	C775.911194,156.038147 773.086548,155.999786 770.262634,155.999969
	C767.445312,156.000153 764.627991,156.000000 760.997437,156.000000
	C760.997437,158.403671 761.273254,160.686340 760.885864,162.850296
	C760.679626,164.002472 759.325928,164.949249 758.029968,165.977264
M779.129822,144.141876
	C773.203064,144.141876 767.276306,144.141876 761.354004,144.141876
	C761.354004,146.972534 761.354004,149.350800 761.354004,151.609283
	C767.758240,151.609283 773.802734,151.609283 779.830017,151.609283
	C779.830017,149.065536 779.830017,146.912476 779.129822,144.141876
z"/>
    <path :fill="smallFontColor" opacity="1.000000" stroke="none"
          d="
M796.000000,154.977539
	C796.000000,149.862335 796.000000,145.242233 796.000000,140.032303
	C804.080505,140.032303 811.834717,139.741135 819.553162,140.141006
	C824.216614,140.382614 825.540771,143.786667 825.014954,151.401245
	C824.706055,155.874680 821.528748,155.945938 818.304626,155.991776
	C812.829895,156.069641 807.354370,156.096542 801.879395,156.161469
	C801.753296,156.162949 801.629639,156.361877 801.138062,156.782684
	C801.138062,159.383942 801.138062,162.310196 801.138062,165.601746
	C799.426331,165.771133 798.040161,165.908310 796.000000,166.110214
	C796.000000,162.340439 796.000000,158.906540 796.000000,154.977539
M819.829041,144.757507
	C813.663208,144.416641 807.497375,144.075760 801.378357,143.737473
	C801.378357,147.022568 801.378357,149.397064 801.378357,151.757477
	C807.682251,151.757477 813.605713,151.757477 819.969971,151.757477
	C819.969971,149.575546 819.969971,147.629135 819.829041,144.757507
z"/>
    <path :fill="logoColor1" opacity="1.000000" stroke="none"
          d="
M53.999413,112.002182
	C65.659996,93.390594 78.316635,75.746788 85.890427,55.257206
	C88.794807,47.399910 90.293106,39.356792 88.168388,30.987154
	C87.978241,30.238140 88.211838,29.381556 88.299957,27.450073
	C89.958557,28.595556 91.180153,29.123360 91.964371,30.029633
	C99.896919,39.196827 100.373802,49.582382 97.189072,60.749298
	C91.290237,81.432945 79.366058,98.869087 67.993172,116.703537
	C59.763969,129.608185 52.269665,143.007919 44.995399,156.482574
	C41.930832,162.159317 41.077084,168.653870 42.558357,175.260025
	C42.847439,176.549271 41.914116,178.112595 41.312187,180.453384
	C36.100163,175.945358 33.394825,171.394180 32.992195,165.487518
	C32.497322,158.227585 33.630207,151.274994 36.964462,144.789703
	C42.540554,133.943924 48.232143,123.157524 53.999413,112.002182
z"/>
    <path :fill="logoColor2" opacity="1.000000" stroke="none"
          d="
M66.706116,80.703094
	C59.695000,92.137169 52.858707,103.241035 46.113575,114.399986
	C38.669117,126.715881 32.258533,139.544495 29.313910,153.722748
	C28.405321,158.097565 29.172703,162.820480 29.172703,167.563110
	C23.754827,162.432739 21.461775,155.584244 21.094757,148.223007
	C20.532309,136.942062 24.669224,126.536926 29.776104,116.948631
	C38.490837,100.586525 48.235367,84.766579 57.739025,68.833237
	C63.916893,58.475742 71.015579,48.500675 73.129814,36.354847
	C74.263351,29.842907 74.058739,23.098030 74.481483,16.067257
	C77.708992,16.896042 79.853531,20.158106 82.040001,23.282148
	C85.941948,28.857296 85.673241,35.038532 84.300018,41.273624
	C81.169403,55.488152 74.207993,68.019035 66.706116,80.703094
z"/>
    <path :fill="logoColor3" opacity="1.000000" stroke="none"
          d="
M1.000000,99.468399
	C2.840725,95.661140 4.697426,92.330978 6.519323,88.981873
	C13.265362,76.580971 19.893589,64.114517 26.770067,51.786465
	C33.072266,40.487976 41.659428,30.266624 44.171204,17.199966
	C45.000126,12.887782 43.727844,8.171688 43.402813,3.443942
	C48.408382,2.415895 55.342461,8.395119 54.778770,15.896701
	C54.340103,21.734465 52.945213,27.980247 50.184437,33.074074
	C41.753761,48.629246 32.502892,63.742126 23.485394,78.976341
	C15.485473,92.491463 8.793519,106.474884 6.964567,122.339264
	C6.816803,123.620972 6.153769,124.843277 5.346603,127.224991
	C3.807733,123.675720 2.799641,121.350647 1.395774,119.012787
	C1.000000,112.645599 1.000000,106.291206 1.000000,99.468399
z"/>
    <path :fill="logoColor4" opacity="1.000000" stroke="none"
          d="
M68.833366,17.000717
	C68.668167,23.082354 69.574577,29.431608 68.135216,35.195034
	C63.954369,51.935917 53.114910,65.310837 43.964916,79.473526
	C33.783272,95.233047 23.999598,111.221176 19.832705,129.744949
	C18.178499,137.098648 18.284370,144.848251 17.647881,151.775833
	C14.436817,147.343185 11.831564,141.274323 11.089251,134.672028
	C9.755578,122.810097 11.986252,111.312996 17.693617,100.939278
	C26.235292,85.413918 35.365448,70.203041 44.558918,55.048653
	C51.336830,43.876038 58.032837,32.688053 59.664532,19.461132
	C60.098896,15.940063 58.718922,12.195172 58.014481,7.504419
	C59.846867,8.492354 61.089481,9.162313 62.668221,10.388926
	C62.824364,15.007668 64.635529,17.152967 68.833366,17.000717
z"/>
    <path :fill="logoColor4" opacity="1.000000" stroke="none"
          d="
M68.806686,16.662140
	C64.635529,17.152967 62.824364,15.007668 63.001820,10.556111
	C64.926193,12.218951 66.853104,14.271257 68.806686,16.662140
z"/>
    <path :fill="logoColor5" opacity="1.000000" stroke="none"
          d="
M58.972694,186.471649
	C64.522270,185.417114 62.368015,189.751129 62.965393,192.702408
	C61.636311,191.021515 60.314991,188.928452 58.972694,186.471649
z"/>
    <path :fill="logoColor5" opacity="1.000000" stroke="none"
          d="
M68.428467,197.000000
	C66.446205,195.913696 64.892403,194.827393 63.155884,193.015656
	C62.368015,189.751129 64.522270,185.417114 58.951710,186.107910
	C57.042614,180.100540 59.951267,174.798386 62.605904,170.186203
	C71.717888,154.354950 81.367264,138.821289 91.182724,123.410439
	C98.830048,111.403709 107.052917,99.623795 110.867027,85.801361
	C112.977325,78.153625 112.937874,69.912689 113.983337,60.945225
	C115.768890,65.797279 117.844048,70.033516 118.877052,74.510231
	C121.802940,87.190025 118.367577,98.975769 111.746712,109.582626
	C100.172783,128.124496 87.638382,146.068161 76.127594,164.647324
	C72.425674,170.622482 70.242073,177.676941 68.182709,184.485306
	C66.655350,189.534805 68.849243,193.826080 73.891388,196.665207
	C72.285645,197.000000 70.571281,197.000000 68.428467,197.000000
z"/>
    <path :fill="logoColor6" opacity="1.000000" stroke="none"
          d="
M55.929565,190.084351
	C46.677979,186.959656 44.519669,177.021957 46.564045,167.917480
	C49.643013,154.205505 57.864483,143.095123 65.003685,131.389084
	C75.556335,114.086067 86.073586,96.737526 95.827370,78.980721
	C101.185768,69.225739 104.793976,58.573345 102.052315,46.956188
	C101.854492,46.117931 102.206459,45.149914 102.434776,42.998753
	C108.621834,51.558659 111.692253,59.503922 110.500656,69.237862
	C108.489388,85.667603 99.249557,98.688866 91.156288,112.235725
	C81.014664,129.211197 70.157578,145.763168 60.189922,162.836899
	C55.619007,170.666489 52.833858,179.363953 56.270397,189.204041
	C56.185345,189.833054 56.057457,189.958694 55.929565,190.084351
z"/>
    <path :fill="logoColor6" opacity="1.000000" stroke="none"
          d="
M56.509064,189.523087
	C56.961288,189.809753 57.217682,190.280746 57.474075,190.751724
	C57.038132,190.700928 56.602184,190.650131 56.047905,190.341827
	C56.057457,189.958694 56.185345,189.833054 56.509064,189.523087
z"/>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
    fontColor: {
      type: String,
    }
  },
  computed: {
    smallFontColor() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#2E8ACA'
    },
    logoColor1() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#2C3587'
    },
    logoColor2() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#2E8ACA'
    },
    logoColor3() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#39C1CF'
    },
    logoColor4() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#00ACDF'
    },
    logoColor5() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#6983A9'
    },
    logoColor6() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#586FB5'
    }
  }
}
</script>
